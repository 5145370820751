@import '../../variables';
@import "~bootstrap/scss/mixins/_breakpoints.scss";

.section {
  margin-top: 3rem !important;
}

.section_main {
  & h4 {
    font-size: calc(2.125rem + 0.2vw);

    @include media-breakpoint-up(xl) {
      font-size: $h4-font-size;
    }
  }

  & p {
    font-size: larger;
    @include media-breakpoint-up(xl) {
      font-size: $font-size-base;
    }
  }
}

.container_buttons {
  margin-top: 0.7rem !important;
  text-align: center;

  @media (min-width: 568px) {
    margin-top: 1.2rem !important;
  }

  @include media-breakpoint-up(md) {
    margin-top: 0 !important;
  }
}

.text_block {
  padding-left: 2rem;
  padding-right: 2rem;
}

.text {
  color: $secondary;
}
