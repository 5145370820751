@import "../../../../../../../../../../variables";

.container_service_type_label {
  width: 11.875rem;
  padding-top: 0.625rem;
  text-align: left;
}

.container_service_type_label_shop {
  color: $blue-700;
}

.container_service_type_label_bakery {
  color: $secondary;
}

