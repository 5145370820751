@import "../../../../../../../../variables";


.nav_link {
  color: $secondary;
  height: 100%;

  & > div:first-child {
    margin-bottom: 1em;
  }

  & > div:not(:first-child) {
    font-size: smaller;
  }


}

.nav_link_active {
  color: $black !important;

  &:focus {
    color: $black !important;
  }
}
