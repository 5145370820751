@import '../../variables';
@import "~bootstrap/scss/mixins/_breakpoints.scss";


.button {
  width: 15em;
  margin-top: 0.5em;

  @media (min-width: 568px) {
    margin-top: 0;
  }

}
