@import "../../../../../../../../../../../../variables";

.container_slider {
  height: 3rem;
  width: 49.5rem;
  padding-top: 1.0625rem;
  padding-bottom: 1.0625rem;
  vertical-align: middle;
  background-image: linear-gradient(to right, transparent 0, transparent 8.9375rem, $gray-400 9rem, transparent 9.0625rem, transparent 100%);
  background-repeat: repeat-x;
  background-size: 12rem 100%;
  display: inline-block;
}
