@import "../../../../../../../../variables";

.step-number-of-workers-slider {
  .rc-slider-rail {
    height: 0.0625rem;
    background-color: $gray-400;
    top: 0.375rem;
  }

  .rc-slider-track {
    background-color: $blue;
  }

  .rc-slider-handle, .rc-slider-handle-dragging {
    border-color: $blue !important;
  }

  .rc-slider-handle {
    &:active {
      box-shadow: 0 0 0 5px $blue-100 !important;

    }
  }

}
