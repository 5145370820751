@import '../../../../variables';

.container_upload_fill_sheet {
  //margin-top: 2.5rem;
  text-align: center;
}

.drop_area_upload_fill_sheet {
  width: 15em;
  border-style: dotted;
  border-color: $blue;
  border-width: $border-width;
  display: inline-block;
  height: 8rem;
  margin-top: 0.5rem;
  border-radius: $border-radius;
  color: $blue;
  cursor: pointer;
}

.drop_area_upload_fill_sheet_active {
  background-color: $blue;
  color: white;

  .drop_area_upload_fill_sheet_icon, .drop_area_fill_sheet_subtext {
    color: white;
  }

}

.drop_area_upload_fill_sheet_icon {
  color: $secondary;
  font-size: $font-size-base * 2;
}

.drop_area_fill_sheet_subtext {
  font-size: $font-size-sm;
  color: $secondary;
}

.input_upload_fill_sheet {
  opacity: 0;
  position: absolute;
  z-index: -1;
  height: 0;
  width: 0;
}

