@import "../../../../../../../../variables";


.container_check {
  height: 3rem;
  width: 13rem;
  margin-left: 1rem;
  border: 1px solid $secondary;
  padding-left: 0;
  text-align: left;
  border-radius: $border-radius;
}

.icon_check {
  margin-right: 2rem;
}

.label_check {
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 0.6875rem;
  cursor: pointer;
}

.input_check {
  float: right !important;
}
