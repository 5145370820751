@import "../../../../../../../../../../../../../../variables";

.container_time_range_indication {
  position: absolute;
  left: -7.25rem;
  width: 6rem;
  top: 0.625rem;
}

.container_shop {
  color: $blue-700;
}

.container_bakery {
  color: $secondary;
}

.time_of_day {
  width: 3rem;
  display: inline-block;
  font-size: $font-size-sm;
}

.start {
  text-align: left;
}

.end {
  text-align: right;
}
