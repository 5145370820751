@import "../../../../../../../../../../../../variables";

.roster-shift-slider {
  .rc-slider-rail {
    height: 0.0625rem;
    background-color: $gray-400;
    top: 0.375rem;
  }
}

.roster-shift-slider-shop {
  .rc-slider-track {
    background-color: $blue-700;
  }

  .rc-slider-handle, .rc-slider-handle-dragging {
    border-color: $blue-700 !important;
  }

  .rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px $blue-300 !important;
  }
}

.roster-shift-slider-bakery {
  .rc-slider-track {
    background-color: $gray-600;
  }

  .rc-slider-handle, .rc-slider-handle-dragging {
    border-color: $gray-600 !important;
  }

  .rc-slider-handle-dragging {
    box-shadow: 0 0 0 5px $gray-400 !important;
  }

}
