@function tint-color($color, $weight) {
  @return mix(white, $color, $weight);
}

@function shade-color($color, $weight) {
  @return mix(black, $color, $weight);
}


$font-size-base: 1rem;
$font-size-sm: $font-size-base * .875;
$font-size-lg: $font-size-base * 1.25;

$h4-font-size: $font-size-base * 1.5;

$border-width: 1px;
$border-radius: .25rem;

$gray-400: #ced4da;
$gray-600: #6c757d;
$gray-900: #212529;
$black: #000;

$secondary: $gray-600;
$dark: $gray-900;

$blue: #0d6efd;
$blue-100: tint-color($blue, 80%);
$blue-300: tint-color($blue, 40%);
$blue-700: shade-color($blue, 40%);
$primary: $blue;

$red: #dc3545;
$danger: $red;

$green: #198754;
$success: $green;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1400px
);

