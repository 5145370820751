@import "../../../../../../variables";

.nav {
  margin-top: 1em;
  border-bottom: 1px solid $gray-400;

  & > div {
    border-right: 1px solid $gray-400;
  }
}

.landing_page_link {
  color: $secondary;
}

