.container_navigation_buttons {
  margin-top: 2rem;
  margin-bottom: 2rem;

  button {
    margin-left: 1rem;
    margin-right: 1rem;
  }
}

.header {
  margin-top: 1em;
  margin-bottom: 1em;

}
