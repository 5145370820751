@import "../../../../../../../../variables";

.container_day_label {
  margin-bottom: 1rem;
  margin-top: 2rem;
  font-size: $font-size-lg;
}

.container_marks {
  text-align: right;
  padding-right: 8.9375rem;
}
