@import "../../../../../../../../../../../../../../variables";

.container_buttons_add_remove {
  display: inline-block;
  font-size: $font-size-lg;
  color: $gray-400;
  margin-left: 1.125rem;
  vertical-align: middle;
}

.button_slider_remove {
  &:hover {
    color: $danger;
  }
}

.button_slider_remove.button_disabled {
  &:hover {
    color: $gray-400;
  }

  cursor: not-allowed;
}

.button_slider_add {
  &:hover {
    color: $success;
  }
}
